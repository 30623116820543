import { AcpCopyToClipboard } from 'shared/angularjs/services/acp-copy-to-clipboard';
import acpAddMoneyLandingTemplate from './templates/acp-add-money-landing.html';

export class AcpAddMoneyLandingComponentCtrl
  implements nsUtils.NsComponentController {
  ddInfo: {};
  hasAlertsTermsAccepted: boolean;
  private reloadedDDInfoOnce = false;
  private _toastSuccessMsg = '';

  constructor(
    nsComponentDecorator,
    private content,
    private $mdToast: ng.material.IToastService,
    private acpCoreDispatcher: any,
    private acpCopyToClipboard: AcpCopyToClipboard,
    private acpDirectDepositModel: any
  ) {
    'ngInject';

    // If methods such as $onValue and $tie are needed:
    nsComponentDecorator(this, this);
  }

  async $onInit() {
    this.$tie('ddInfo', this.acpCoreDispatcher.account.directDepositInfo);
    this.$tiePermissions('permissions', ['displayAccountDetailsCFPB']);
    this.$onValue(
      this.acpCoreDispatcher.account.directDepositInfo,
      (ddInfo) => {
        if (!ddInfo && !this.reloadedDDInfoOnce) {
          this.acpDirectDepositModel.fetchDDInformation();
        }
        this.reloadedDDInfoOnce = true;
      }
    );
    const toastSuccessMsg = await this.content.getKey(
      'toast-success',
      'components/add-money-landing'
    );
    this._toastSuccessMsg = toastSuccessMsg.copy;
  }

  // These are needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  $tie?<T>(property: string, signal: nsUtils.NsSignal<T>);
  $tiePermissions?(property: string, permissions: string[]);
  $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);

  copyDetail(detail: string): void {
    this.acpCopyToClipboard.copy(detail, () => {
      this.$mdToast.show(
        this.$mdToast
          .simple()
          .textContent(this._toastSuccessMsg)
          .parent('.add-money-landing-content')
          .hideDelay(2000)
      );
    });
  }
}

export const acpAddMoneyLandingComponent = {
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpAddMoneyLandingComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpAddMoneyLandingTemplate
};
