import ng from 'angular';
import acpCore from 'core';
import acpCopyToClipboard from 'shared/angularjs/services/acp-copy-to-clipboard';
import { acpAddMoneyLandingComponent } from './acp-add-money-landing-component';
import addMoneyLandingContent from './acp-add-money-landing-content.yml';

export const acpComponentAddMoneyLandingModule = ng
  .module('acp.component.add-money-landing', [
    acpCore.name,
    acpCopyToClipboard.name
  ])
  .component('acpAddMoneyLanding', acpAddMoneyLandingComponent)
  .run(
    /*@ngInject*/ function (contentSectionCache) {
      contentSectionCache.put(
        'components/add-money-landing',
        addMoneyLandingContent
      );
    }
  );

export default acpComponentAddMoneyLandingModule;
